<template>
  <div class="mobileHead">
    <div
      style="min-height:0.8rem;background-color:#414141"
      :style="{display:showFixed?'block':'none'}"
    ></div>
    <div class="header" :style="{position:showFixed?'fixed':'',top:showFixed?'0':''}">
      <van-dropdown-menu>
        <van-dropdown-item :title="name" ref="item">
          <div class="li" @click="change(urlOne)">
            <a :style="{color:col?'#fff':'#999'}">概览</a>
          </div>
          <div class="li" @click="change(urlTwo)">
            <a :style="{color:col?'#999':'#fff'}">安装参数</a>
          </div>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "add", "adds", "color"],
  data() {
    return {
      Name: this.name,
      urlOne: this.add,
      urlTwo: this.adds,
      col: this.color,
      scrollTop:0,
      showFixed: false,
      n: ""
    };
  },
  created() {
    this.handleScroll();
    var that = this;
    window.screenWidth = document.body.clientWidth;
    that.n = window.screenWidth / 7.5;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.n = window.screenWidth / 7.5;
      })();
    };
  },
  methods: {
    change(href) {
      this.$router.push(href);
    },
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    }
  },
  watch: {
    // 监测滚动距离固定导航条
    scrollTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0.96 * this.n) {
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    }
  }
};
</script>
<style scoped lang="less">
/deep/.van-dropdown-menu ,/deep/.van-dropdown-menu__bar {
  background-color: #414141;
  height: 0.8rem;
}
/deep/ .van-popup {
  background-color: #000;
}
/deep/.van-dropdown-menu__item {
  padding: 0px 0.3rem;
  display: flex;
  height: 0.8rem;
  align-items: center;
  justify-content: center;
  text-align: left;
  opacity: 1;
  .van-dropdown-menu__title {
    width: 100%;
    padding: 0;
    margin-right: 0.2rem;
    color: #999;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0.12rem;
      right: 0px;
      border: 0;
      opacity: 1;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(135deg);
      transition: all 0.3s;
      width: 0.16rem;
      height: 0.16rem;
      margin-top: 0px;
    }
  }
  .van-dropdown-menu__title--active {
    color: #fff;
    &::after {
      transform: rotate(-45deg);
      top: 0.16rem;
    }
  }
}
.header {
  width: 100%;
  height: 0.8rem;
  z-index: 99;
  background-color: #414141;
  .li {
    margin: 0px 0.3rem;
    height: 0.8rem;
    border-bottom: 1px solid #999999;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-of-type {
      border-bottom: 0px;
    }
    a {
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>