<template>
  <div class="mobileGatewayParam">
    <mHead :name="'插卡取电'" :add="'/mobile/ProCard'" :adds="'/mobile/ProCardParam'" :color="false" />
    <div class="param">
      <div class="title">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LSS02A</div>
      </div>
      <div class="text">
        <div class="left">产品尺寸:</div>
        <div class="right">符合86盒标准：86×86mm</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">AC200V~250V/50Hz</div>
      </div>
      <div class="text">
        <div class="left">额定输出:</div>
        <div class="right">220V/30A</div>
      </div>
      <div class="text">
        <div class="left">工作温度:</div>
        <div class="right">0°C-40°C</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>
    </div>
    <div class="paramDe">
      <div class="txt">插卡取电面板可控制客房内除UPS电源以外的所有电源的通断。当客人把房卡插入面板后，面板会读取卡内的客房信息并上报主机进行有效性确认。只有授权有效的房卡才能使插卡取电面板开启房间内的电源。当客人出门拔卡后，插卡取电面板会继续维持房间电源，直至30s延时结束后断电。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
import mHead from "../../components/MobileHead.vue";
export default {
  components: {
    mHead
  },
  data() {
    return {};
  }
};
</script>
<style scoped lang="less">
.mobileGatewayParam {
  background-color: #fff;
  .param {
    margin: 0 0.3rem;
    padding-bottom: 0.26rem;
    border-bottom: 1px solid #333;
    .title {
      margin-top: 0.8rem;
      font-size: 0.48rem;
      line-height: 0.48rem;
      font-weight: bold;
      color: #ff9000;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0.59rem;
      }
      .left {
        // width: 1.36rem;
        text-align: right;
      }
      .right {
        margin-left: 0.6rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0px 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;
      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>
